


































import { notifyError, notifyInfo } from "@/components/Notification";
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "remove-appointment",
  props: {
    value: Boolean,
    event: {
      type: Object,
      default: () => ({
        date: {
          doctor: {
            fullname: "",
          },
          patient: {
            fullname: "",
          },
          partial: {
            uuid: "",
          },
        },
      }),
    },
  },
  computed: {
    ...mapState("crmAppointmentsModule", ["loading"]),
  },
  methods: {
    ...mapActions("crmAppointmentsModule", ["actRemoveAppointment"]),
    ...mapMutations("crmAppointmentsModule", ["mutLoading"]),
    remove() {
      this.actRemoveAppointment(this.event.data.partial.uuid)
        .then(() => {
          this.close();
          this.$emit("removed");
          notifyInfo("Appointment removed successfully");
        })
        .catch((error: any) => {
          this.mutLoading(false);
          notifyError(
            (error as any).response.data.message ||
              (error as any).response.data.message
          );
          this.$emit("error-removed");
        });
    },
    close() {
      this.$emit("input", false);
    },
  },
});
